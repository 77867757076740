<template>
  <div class="body">
    <h1>学校简介</h1>
    <p>一、办学定位</p>
    <p>
      泸州职业技术学院是经四川省人民政府批准、国家教育部备案的综合性公办普通高等学校。以全日制高职专科学历教育为主，兼顾成人学历教育、中外合作学历教育和社会培训，具有接收外国留学生资质和聘请外教专家资格。2014年，四川省人民政府审定同意学校增挂泸州技师学院牌子。
    </p>
    <p>二、悠久历史</p>
    <p>
      学校前身是川南经纬学堂，成立于1901年，是川南第一所“新学”。清翰林院国史馆编修、晚清第一词人赵熙为首任校长，朱德、恽代英等曾在这里辛勤耕耘，培育出吴玉章、曾德林、黎英海等知名校友。
    </p>
    <p>三、主要荣誉</p>
    <p>
      学校获评四川省“双高”院校B档、本科层次职教人才培养试点校、国家级高技能人才培训基地、国家级现代学徒制试点单位、教育部“一站式”学生社区综合管理模式建设试点单位、全国职业院校数字校园建设实验校、全国职业院校民族文化传承与创新示范专业点、全国五四红旗团委、世界技能大赛选拔集训基地、国家首批示范性技工教育联盟（集团）建设单位、四川省职业院校“三全育人”典型学校、四川省普通高等学校毕业生就业工作先进单位、四川省首批创新创业教育实践基地、四川省大众创业万众创新示范基地、四川省创业孵化基地、中国酒业人才培训基地、四川省省级科技工作者状况调查站点、四川省首批大中小学思想政治教育一体化基地、四川省特色马克思主义学院、四川省家风家教创新实践基地、赤水河流域非物质文化遗产普及基地、四川省首批优质兵员预征预储试点单位、四川省技师学院联盟首任理事长单位、全国生态文明教育特色学校、四川省文明校园、四川省首届美丽校园。
    </p>
    <p>四、办学条件</p>
    <p>
      学校坐落于激流澎湃的长江之滨、沱江之畔，开设46个高职专科专业，在校生15000余人，占地1504亩。拥有纸质图书1070527册，数字资源量3199834册（小时）的现代化图文信息中心，中央财政支持建设实训基地2个（电子电工实训基地、建筑工程实训基地）、国家级生产性实训基地1个（工程造价生产性实训基地）、成渝双城经济圈川南公共实训基地、川南最大的诺博幼儿园、五星级首旅泸州建国饭店、四星级洲际龙涧假日酒店、酒城文化创意美食街、天猫超市等优越的校内实习实训基地，室内体育馆、恒温游泳馆，温馨舒适的标准化宿舍、食堂，为师生提供一流的学习生活保障。
    </p>
    <p>五、师资队伍</p>
    <p>
      学校现有教职工734人，其中国际系统与控制科学院院士1人，国务院特殊津贴专家3人，全国新时代职业学校名师1人、四川省天府峨眉计划创新领军人才、四川省“天府学者”特聘专家4人，教授57人，省级技术能手10人，省级学术和技术带头人后备人选8人，常驻外籍教师2人。聘请第十二届全国政协委员、财政部财科所原所长贾康，中华诗词学会副会长、第六届鲁迅文学奖诗歌奖得主、四川大学教授周啸天，央视《百家讲坛》主讲嘉宾、重庆大学博士生导师曾国平等兼职教师439人。建有专家工作站1个、省级“双师型”名师工作室2个、省级紧缺领域教师技艺技能传承创新平台1个、省级教师教育实践基地1个。
    </p>
    <p>
      近5年，教师完成省级科研项目315项，获全军科学技术进步奖7项、省级科学技术进步奖7项、省级哲学社会科学优秀成果奖4项、专利292项；获国家级教学成果二等奖1项，省级教学成果奖一等奖4项、二等奖2项，省级教育科研成果奖22项。教师在教学能力大赛、辅导员素质能力大赛等省级以上教师技能大赛中获奖220余人。
    </p>
    <p>六、专业建设</p>
    <p>
      学校全力贯彻落实党中央推动成渝地区双城经济圈建设的重大战略部署，主动对接四川省、泸州市食品轻纺、电子信息、装备制造、现代服务等现代产业体系布局，服务区域经济发展，推进专业群对接产业链建设结合学校办学实际，形成4个（酿酒技术、学前教育、大数据技术、酒店管理与数字化运营）龙头引领，3个（机电一体化技术、电子信息工程技术、智能建造技术）骨干支撑，2个（艺术设计、大数据与会计）特色协同的专业群，并不断优化调整。全面推进“一院一品”和“一专业一模式”建设，实行“1+1+1”（即一个专业对接一个大型国企或上市公司，依托一个本科院校，科研院所或行业协会）办学改革，推进专业群创新发展，打造专业建设引领产业发展，课程设置紧跟专业调整的格局，有效提升学校专业群服务高端产业能力。学校与中国兵器集团、中国酒业协会、中国电科、中交集团、郎酒集团、泸州老窖、华为、腾讯、国信蓝桥、洲际集团等龙头企业合作，实现毕业生高质量就业。
    </p>
    <p>七、产教融合</p>
    <p>
      学校依托四川经纬教育管理集团，引入国际、国内行业领先标准和管理团队，高标准建成泸州建国饭店、泸州龙涧假日酒店、酒城文化创意美食街、经纬诺博幼儿园等19个实体化运营的校内生产性实训基地。与华为、中国兵器、泸州老窖、川南火工等行业龙头，共建国防产业学院、川南火工先进制造产业学院等20余个产教融合平台，以平台链接产业，打造兼具自我造血和育人功能的校企共同体，实现学校办学全面融入区域发展和产业转型。
    </p>
    <p>
      学校开创了“内生性产教融合”育人模式，形成了产教融合的“泸职模式”。牵头组建中国酒业产教融合共同体和全国首个跨省级行政区的产教联合体－－泸永江融合发展示范区产教联合体，“政校行企研园”六方协同探索创新治理机制、育训模式、服务方式，努力推动形成学校办学与地方产业一体化高质量发展的产教融合新形态。建设中国白酒生态智能酿造产教融合示范园，在园内打造世界蒸馏酒教学生产型展馆和白酒培训、比赛中试、酒文化展示、旅游观光基地，集人才培养、技能培训鉴定、生产经营、科普教育、研学观光、体验营销于一体，打造面向中国白酒行业的开放型区域产教融合实践中心，服务区域支柱白酒产业高质量发展。
    </p>
    <p>八、国际合作</p>
    <p>
      学校贯彻落实“一带一路”倡议和新时代教育对外开放要求，坚持“走出去”“引进来”的国际化办学思路，
      助力打造南向开放新高地。学校与俄罗斯弗拉基米尔国立大学签订合作办学协议，与老挝琅勃拉邦省教育与体育厅签署《合作框架协议》，开展“国内高职+国外本硕”贯通培养；
      在老挝、越南建设2个高水平海外培训基地“经纬工坊”，开展“中文+职业技能”培训；
      在新加坡、越南建设2个海外分校“经纬学院”，开展学生联合培养；
      向坦桑尼亚、老挝等国输出教学标准10余项，促进职业教育发展；
      在老挝成立“中老儿童教育研究中心”，承办首届中老儿童教育国际论坛；现开设早期教育、大数据技术、市场营销、酒店管理与数字化运营、工业机器人等国际化专业，先后培养来自南非、老挝、孟加拉国、塔吉克斯坦、巴基斯坦、越南等国家留学生200余人；积极拓展海外高质量就业，向美国、新加坡、德国和阿联酋（迪拜）等国输送优秀毕业生50余人。
    </p>
    <p>九、教学科研</p>
    <p>
      学校有国家骨干专业3个（学前教育专业、市场营销专业、机电一体化技术专业），中央财政支持建设专业2个（学前教育专业、新能源汽车技术专业），国家级现代学徒制专业1个（酿酒技术专业），国家1+X试点证书17个，全国职业院校民族文化传承与创新示范专业点1个（艺术设计专业）。建有省级课程思政标杆院系1个，职业教育国家在线精品课程1门、省级精品资源共享课程2门、省级精品在线开放课程8门，省级虚拟仿真实训基地1个、省级教学资源库3个，主持省级教改项目28项。教师出版学术专著36部，发表核心期刊论文500余篇（SCI和EI收录50余篇）；教师主持教育部人文社会科学规划基金项目、科技部高端外国专家引进计划项目、中国科学技术协会“翱翔之翼”大学生科技志愿服务项目、中国高等教育学会，中国职业技术教育学会等7项国家级科研项目；5本教材入选“十四五”职业教育国家规划教材，12本入选四川省首批“十四五”
      职业教育省级规划教材。
    </p>
    <p>
      学校高度重视学生专业技能及综合素质培养，学生先后在全国移动互联网应用软件开发大赛、全国大数据技术与应用大赛等技能竞赛中获奖1100余人次，其中省级一等奖以上240余人次，涌现出共青团第十八次全国代表大会代表林佳，中国大学生自强之星杨智健、钟宏雄、淦永梅、孙菁、曾杰、梅杰，四川省大学生年度人物邓应丹，四川省优秀共青团员曾悦，四川省勤学奋进新青年林厚坤等一大批优秀学生。
    </p>
    <p>十、社会培训</p>
    <p>
      学校是四川省首批第三方评价机构职业技能等级认定试点单位，可承担砌筑工、钢筋工、工业机器人系统操作员等在内的28个职业49个工种中、高级工，技师及高级技师技能等级认定。学校立足泸州、面向四川、辐射滇黔渝，成立成渝地区双城经济圈（泸州）商学院，积极开展继续教育和职业培训，是“国家级高技能人才培训基地”“国家职业核心能力培训认证项目培训基地”“全国职工教育培训示范点”“四川省职业技能竞赛集训基地”“四川省退役军人专业技能承训机构”“省委组织部大学生村干部创业培训基地”“四川省统计人才培训基地”“四川省高素质农民培育省级实习实训基地”“市委组织部农村基层干部培训基地”。每年承担各类社会培训近100000人次。
    </p>
  </div>
</template>
<script>
export default {
  name: "Sub6"
};
</script>

<style scoped lang="less">
.body {
  width: 1440px;
  margin: 160px auto 80px;

  h1 {
    text-align: center;
  }

  .bold {
    font-weight: bold;
    font-size: 22px;
    text-indent: 2em;
  }

  p {
    text-indent: 2em;
    margin: 30px;
    color: #333;
    font-size: 18px;
    line-height: 30px;
  }

  .post {
    text-align: center;
    color: #333;
    margin: 0;
    margin-bottom: 80px;
  }

  .flex-2 {
    text-align: center;

    img {
      margin: 20px;
      margin-top: 0;
      width: 640px;
      height: 400px;
    }
    .img1 {
      margin: 40px;
      margin-top: 0;
      width: 820px;
      height: 600px;
    }

    .img2 {
      margin: 40px;
      margin-top: 0;
      width: 420px;
      height: 600px;
    }
  }

  .flex-3 {
    text-align: center;

    img {
      margin: 20px;
      margin-top: 0;
      width: 420px;
      height: 600px;
    }
  }

  .img-container {
    text-align: center;

    img {
      margin: 20px auto;
      width: 1320px;
    }
  }
}
</style>
