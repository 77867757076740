<template>
  <div class="body">
    <h1>学院概况</h1>
    <p>
      宝鸡职业技术学院是2003年4月经陕西省人民政府批准，教育部备案的一所公办全日制普通高等职业院校，地处华夏始祖炎帝故里、周秦文化发祥地、中国西部工业重镇、陕西省第二大城市——宝鸡市。
    </p>
    <p>
      学院有120年的办学历史，继承了陕西凤翔师范学校（1902年）、宝鸡市卫生学校（1934年）、宝鸡市中医药学校（1952年）、陕西省宝鸡农业学校（1959年）、宝鸡市工业学校（1964年）、宝鸡市财经学校（1978年）、宝鸡师范学校（1992年）等原市属7所国家级、省部级重点中专的职教传统，在省内率先通过教育部高职高专人才培养工作水平评估，现为省级“双高计划”建设单位、省级示范性高职院校、省级文明校园、省级平安校园、省级园林式单位、省级智慧校园示范校、全省普通高校心理健康教育与咨询示范中心、国家乡村振兴人才培养优质校、中国职教就业百强院校。2019、2021年度全市目标责任考核获优秀等次，2020年、2022年在全国高职师范类排行榜中名列第一。
    </p>
    <p>
      学院位于宝鸡国家高新技术产业开发区东区，校区占地2300余亩，总建筑面积73.83万平方米，固定资产总值18.78亿元；各类藏书187.39万册，教学仪器设备总值2.2亿元；设有15个党政群部门、6个二级学院、6个教学辅助部门和1所二级甲等附属医院（宝鸡第二人民医院，创办于1937年）；教职工和医务工作者1528人，其中专任教师917人，有正高职称38人、副高职称337人，具有博士、硕士学位的教师329人，双师型教师608人；全日制在校生1.7万人，开设三年制高职专业54个，涵盖12个专业大类、33个专业类；现有国家级骨干专业1个、教育部“1+X证书制度”试点项目23个、省级一流培育专业5个、省级骨干专业5个、省级专业综合改革项目3个、省级重点专业8个、省级精品在线开放课程7门、省级创新创业教育课程3门、省级优秀教材6部、省级优秀教学团队2个，省级示范性实训基地6个；现有全国优秀教师1人、全国优秀思想政治教育工作者1人、省级优秀教师3人、省级教学名师6人、省级师德先进个人2人、省级师德标兵2人、省级优秀教育工作者4人、市级有突出贡献拔尖人才3人、市级爱国奋斗人才标兵1人、市“三五人才工程”第二层次人选1人、市级高端科技创新领军人才1人、市级“五一劳动奖章”获得者3人。近三年来，获省级教学成果一等奖1项、教师荣获国家级、省级各类教学竞赛一、二、三等奖97项，学生在全省技能大赛获一、二、三等奖141项，在全国技能大赛中获一等奖1项、二等奖3项、三等奖5项，在全省高职院校中处于领先水平。
    </p>
    <p>
      学院秉承百年办学优良传统，始终坚持以服务为宗旨，以就业为导向，走产教融合发展道路，大力弘扬“精诚致功，厚己达人”的大学精神，积极践行“敦品尚能，学养日新”的校训，努力培育“公诚勤毅”的校风、“崇德敬业，严谨执教”的教风、“勤奋博学，善思笃行”的学风，以“双高”院校建设为目标，以专业建设、课程建设、团队建设、实训条件建设、质量保证体系建设为抓手，不断深化教育教学改革，全面提升人才培养质量和整体办学水平。
    </p>
    <p>
      学院按照内涵式与外延式发展并举、职业化与特色化并重的思路，形成以师范教育、医药卫生、装备制造、电子信息、财经商贸、土木建筑、生物与化工、农林牧渔、文化艺术等12个专业大类为主，融职业教育、技能培训、技术服务于一体的办学格局。校内各类实验实训场所83354平方米，有幼小教育、宝鸡（国际）职业技能实训中心两大基地，有金工、汽车检测与维修、电子、计算机、医护医药、财经商贸、农林畜牧、建筑测绘、艺体训练等实训中心11个，各类实验实训室241个，各类实践基地188个，被宝鸡市政府列为中国西部职业教育综合性实训基地。
    </p>
    <p>
      学院遵循“产教融合、校企合作、工学结合、育训并举”的理念，积极推进基地、招生、教学、科研、就业“五位一体”合作模式。与省内外400余家大中型企事业单位建立了合作关系，与吉利集团、陕西建工集团、西安卓越新能源应用人才发展中心共建8个产业学院，与宝鸡机床集团、陕西汽车控股有限公司、陕西法士特齿轮有限公司开展“现代学徒制试点”项目，开设各类订单培养项目37个，成立了以国家级首批技能大师工作室带头人巨晓林为代表的技能大师工作室10个，基本实现开设专业校企合作项目全覆盖，为学生搭建了广阔的就业创业平台，实现了企业与人才的无缝对接。建院以来，为社会培养高技能人才18万余名，应届毕业生平均就业率达95%以上。招生扩大到全国15个省、市、自治区，学生规模在陕西市属高职院校中处于前列。
    </p>
    <p>
      近年来，学院强力推进基础设施建设，教学功能更加完备。投资10.8亿元（基建投资5.18亿元）建设宝鸡(国际)职业技能实训中心；投资2.28亿元建设图书综合楼；投资1.47亿元建成高标准学生餐厅和标准化公寓；投资3592万元建成第十四届全运会足球主赛场，新建学生公寓尚礼楼、尚品楼、尚能楼，新改造文体中心、学术报告厅、崇文路操场等设备功能齐全，条件优越；投资4580万元建成省级智慧校园示范校，实现信息化教学、管理、服务整体提升；与高新区管委会共建的宝鸡高新凤师实验小学和幼儿园，使学院师范教育与地方基础教育一体贯通融合发展。
    </p>
    <p>
      学院将立足宝鸡，面向西部，辐射全国，按照“十四五”规划“15369”战略，深化产教融合、校企合作，加强内涵建设，促进质量提升，积极打造适应区域社会经济发展需求的优势特色专业，建设一支高水平的双师素质型教师队伍，建成西部最大的区域性产教融合实训基地，积极打造技术技能人才培养和协同创新高地，优势专业在省内保持领先地位，人才培养质量得到社会高度认可，努力向建设全国“双高”高职院校的奋斗目标迈进。
    </p>
  </div>
</template>
<script>
export default {
  name: "Sub10"
};
</script>

<style scoped lang="less">
.body {
  width: 1440px;
  margin: 160px auto 80px;
  text-align: center;

  p {
    text-align: left;
  }

  .bold {
    font-weight: bold;
  }

  p {
    text-indent: 2em;
    margin: 30px;
    color: #333;
    font-size: 18px;
    line-height: 30px;
  }

  img {
    width: 1040px;
    display: inline-block;
    margin-bottom: 15px;
  }

  .img-container {
    img {
      margin: 40px;
      margin-top: 0;
      width: 550px;
      height: 400px;
    }
  }

  .p-image {
    img {
      width: 1040px;
      display: inline-block;
      margin-bottom: 5px;
    }

    .post {
      text-align: center;
      font-size: 15px;
      margin: 0 0 30px;
      color: #666;
    }
  }
}
</style>
