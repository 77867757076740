<template>
  <div class="body">
    <h1>学院简介</h1>
    <p>
      威海海洋职业学院坐落于美丽的海滨之城——山东威海，是一所经山东省人民政府批准、国家教育部备案的国有公办全日制普通高等专科学校。学院以习近平新时代中国特色社会主义思想为指导，深入贯彻落实党的二十大和习近平总书记关于职业教育的重要指示批示精神，坚守为党育人、为国育才总体要求，以提高人才培养质量为核心，以提质培优、增值赋能为主线，落实立德树人根本任务，深化产教融合、校企合作，推动实现治理体系和治理能力现代化，全面实施“质量立校、人才强校、特色兴校、开放活校”发展战略，着力增强职业教育适应性，以高质量发展稳步向建设“双高”院校目标迈进。
    </p>
    <p>
      加强党建引领，坚定不移推进全面从严治党。严格落实“第一议题”制度，时刻与习近平总书记的指示批示和各级党委政府的部署要求对标对表，以实际行动自觉拥护“两个确立”,进一步增强“四个意识”、坚定“四个自信”、做到“两个维护”。认真贯彻落实中央关于全面从严治党的部署要求，把管党治党与办学治校、改革发展、安全稳定等中心工作同部署、同落实、同考核。增强思政工作的责任感和使命感，着力构筑具有凝聚力和引领力的社会主义意识形态阵地。牢固树立抓好党建是最大政绩的理念，持续深化党支部规范提升行动，全院现有21个基层党支部，标准化规范化建设达标率100%，获评山东省教育系统先进基层党组织2个，山东省党建工作样板支部2个，山东省教育系统党建先锋岗1个，威海市教育系统五星级党支部4个。
    </p>
    <p>
      服务现代海洋产业，打造特色高水平专业群。根据区域产业发展需求，重点打造水产养殖技术、海洋健康食品、药品与医疗器械、船舶智能制造、人工智能物联网、国际邮轮乘务管理、工业机器人技术、电子商务技术8大专业群，建有2个省级高水平专业群、2个省级品牌专业群、3个全国高等职业教育创新发展行动计划骨干专业。与临沂大学、山东农业工程学院、山东管理学院三所本科高校，青岛蔚蓝生物股份有限公司、浪潮软件股份有限公司、山东京东快星供应链科技有限公司三家行业头部企业、专精特新企业达成合作意向，共同开展水生动物医学（本科专业：动物医学）、人工智能技术应用（本科专业：人工智能）、港口物流管理（本科专业：物流管理）专业的对口贯通分段培养工作。深化“专业群＋技术技能创新平台群两群融合”“创新创业教育双创引领”“优秀传统文化、红色革命文化、蓝色海洋文化三化塑造”人才培养模式改革，推行项目教学、成果导向教学，实施因材施教分层次培养，注重提升学生职业发展核心竞争力，荣获职业院校技能大赛国家级奖项9项、省级奖项753项。
    </p>
    <p>
      搭建成长平台，推进“名师工程”。实施教师成长工程，制定了新入职教师、青年教师、骨干教师、专业带头人、专家5个阶段“阶梯式”发展标准，搭建教师专业成长平台，建设教师企业实践基地（流动站）38个，打造一支师德高尚、业务精湛、结构合理、数量充足的“双师型”教师队伍，平均年龄35岁左右，硕士及以上学历占比82.5%。加强名师引领工程，拥有4名二级教授、8名国家级及省级教学名师、1名全国技术能手、1名山东省技术技能大师、2名山东省青年技能名师、1名齐鲁首席技师、6名山东省技术能手，建有1个省级黄大年式教师团队、1个省级教学团队、9个省级教学创新团队、4个省级名师工作室和2个省级技艺技能传承创新平台，通过名师引领提升师资队伍整体专业水平。
    </p>
    <p>
      设施设备先进，办学条件一流。设有航海模拟实训室、轮机模拟实训室、大学生电子商务创业基地、微藻饵料藻种培养实训室等专业实训室，现代海洋协同创新虚拟仿真实训基地纳入教育部提质培优行动计划项目管理，中德智能制造人才培养示范基地被评为新一代信息技术与制造业融合发展示范单位，海洋装备与智能制造实训基地被评为山东省新旧动能转换公共实训基地。图书馆、体育馆、报告厅设施设备配备齐全，功能先进，为学生丰富知识、增长见识、锻炼体魄搭建了平台。深入贯彻落实习近平生态文明思想，积极开展绿色低碳健康行动，荣获“山东省绿色学校”。
    </p>
    <p>
      打造科研平台，增强服务能力。高效运行“山东省船舶控制工程与智能系统工程技术研究中心”“山东省船舶智能装置与系统应用技术协同创新中心”“山东省海洋经济藻类资源开发与利用工程技术协同创新中心”“山东省高等学校高技术船舶控制工程技术研发中心”等21个省、市级科研平台、创新平台，加快推进各种专利、项目研究，促进科研成果的转移转化，《威海市碳汇渔业现状及发展对策》等研究成果被市委采纳；国家级教科研项目立项数量在全国高职院校中位居第25名；学院在中国高职院校科研与社会服务竞争力、课题竞争力、科技创新活力、发明专利授权排行榜中位列第105名、第65名、第38名、第29名。成立了“威海市海洋生物健康促进中心”，构建科学规范的海洋生物疫病防控体系，为区域海洋经济创新绿色发展提供技术支撑。实施学历教育和非学历教育并举，对全市各类专业技术人员提供继续教育网络在线培训服务，积极发挥作为“山东省海洋与渔业实用人才培训基地”、“威海市技能鉴定所”等培训基地和鉴定机构的职能，开展各类实用人才培训和技能鉴定，被评为“山东省乡村振兴示范性职业院校”，入选“2021-2025年第一批全国科普教育基地名单”。积极赋能乡村振兴，建立乡村振兴项目库282项，形成30个项目清单，《绿色创新，赋能海洋蓝色经济高质量发展》案例获评全国教育赋能乡村振兴典型案例，被授予“乡村振兴电商人才培养示范基地暨村村播工程示范校”,荣获2023年度“电商助力乡村振兴先进单位”。
    </p>
    <p>
      狠抓质量工程，加快信息化建设。全力推进质量保障体系建设，完善学校、专业、课程、教师、学生不同层面的质量保证机制，实施全面、全员、全过程质量管理，成为山东省第一批内部质保体系诊改工作实施方案审核通过的高职院校之一。加强文献信息资源基础建设、精品共享课程资源库建设、虚拟仿真实训平台建设，成立“山东省移动云教学大数据研究中心”，利用好蓝墨云班课等数据平台，拓展网络学习空间应用的广度与深度，构建网络化、数字化、个性化、终身化的学习体系；以数字化为契机，不断重塑人才培养机制、培养模式、管理方式，打造数字化智慧校园，被山东省教育厅确定为教育信息化示范单位，入选全国职业院校“智慧校园50强”、“全国智慧校园示范高校”，被教育部评为国家职业教育信息化标杆学校首批试点单位和实验校。
    </p>
    <p>
      重视创新创业，夯实就业保障。“大学生双创孵化基地”荣获全国大学生创新创业就业服务基地，打造了以市场为主导、以企业为指导、以学生为主体、以高校为依托的“产学研”紧密结合的创新创业校企合作模式。已孵化企业210余家，大学生创业项目荣获2018年中国“互联网+”大学生创新创业大赛银奖，荣获山东省首届大学生就业创业知识竞赛“优秀组织奖”，大学生双创协会蝉联两届全国百强创业社团，荣获全国大学生微创业行动优秀组织奖，学院被省委、省政府授予“山东省就业创业工作先进集体”，被全国高校毕业生就业协会授予“2020年高校毕业生就业创业工作典型案例学校”，入选全国职业院校“双创100强”，获评金平果全国高职院校“创新创业100强”，入选山东省高校创新创业教育工作典型案例，校友宋希尧荣获“山东优秀大学生创业者”称号。
    </p>
    <p>
      坚持立德树人，提升学生素养。加快大思政格局的构建与实践，打造全环境立德树人“生态圈”。明德文化中心以“优秀传统文化、红色革命文化与蓝色海洋文化”三种文化为育人主线，构建了“五位一体”的传统文化养成教育体系、“一剧、二馆、三课、四平台”的红色文化育人体系以及“一课九平台”的海洋文化育人体系，现有公共选修课100门，通过传统文化熏陶、职业发展规划、社团活动开展等，培养学生的人文情怀、拼搏精神、团队意识、领导能力、社交能力、表达能力、学习能力、全球视野和社会责任感，帮助学生找到适合自我发展的方向和兴趣，为人生的长跑提供源源不断的动力和能量，被团中央授予“‘青年之声’国学教育示范基地”，获批威海市关心下一代教育基地，“三化”育人项目入选山东教育展示传播“品牌系列”，思政育人经验做法被《光明日报》《大众日报》《中国教育报》等主流媒体重点报道，被山东省教育厅《教育工作简报》专版刊登，关工委工作在全省做经验交流。充分发挥第二课堂育人功能，连续两年荣获共青团“第二课堂成绩单”评价平均分榜单全国第一。推进五育创新发展，215名学生成为“齐鲁工匠后备人才”，2个常态化实践项目荣获山东省志愿服务大赛铜奖，1名同学入选全国大学生西部计划。
    </p>
    <p>
      校政企多方联动，产学研深度融合。成立了由政府、学院、园区和产业龙头企业共同组成的理事会，构建“合作办学、合作育人、合作就业、合作发展”的新机制；作为理事单位牵头成立了海洋生物与健康食品、数字经济等2个省级职业教育集团；牵头成立全国低碳渔业与海洋食品质量安全检测、山东省海洋装备智能制造、山东省船舶智能制造3个行业产教融合共同体；与威海市海洋高新技术产业园牵头共建“威海海洋高新技术产教联合体”，获批第一批市域产教联合体；与中国电子、中国联通、青岛国实、科大讯飞等510家大型重点企业开展深入的校企合作；与威高集团、中电、西门子等共建产业学院，共同致力于高素质技术技能人才培养；产教融合案例入选教育部产教融合校企合作典型案例，入选山东省职业教育校企合作典型生产实践项目，入选全国职业院校“产教融合50强”、金平果全国职业院校“产教融合100强”，教育部“中外人文交流全媒体产教融合项目合作院校”，被授予“山东省校企合作先进单位”。
    </p>
    <p>
      注重对外交流，加强境内外合作。建成工信部中德智能制造产教融合人才培养示范基地，当选中德职业教育产教联盟中方理事会常务副理事单位、中德职业教育与产教融合合作示范项目、中德职业技术教育网对德合作试点示范单位、中国-东盟职业教育国际合作联盟常务理事单位、上海合作组织成员国职业教育联盟理事单位、泰中教育联盟会员单位，入选教育部“智能制造领域中外人文交流人才培养基地项目”第二批筹建合作院校；积极响应“一带一路”国际产学研合作联盟威海倡议，与韩国国立庆尚大学、韩国湖源大学、韩国金泉大学、韩国国立交通大学、俄罗斯伊热夫斯克国立技术大学等签订合作协议，就人才培养、文化交流等达成合作意向，境外合作高校达到22所，获批中外合作办学项目2个；成功组织教育部“汉语桥”线上团组项目，成功申报“中外人文交流全媒体产教融合人才培养基地”“中外人文交流经世项目”“坦桑尼亚与冈比亚国家职教标准开发项目”，积极参与缅甸万宝校区与老挝万象校区建设工作，实体化运行泰国宣素那他海外分校。
    </p>
    <p>
      学院是“中国职业技术教育学会职教质量保障与评估研究委员会常务理事单位”“全国船舶工业职业教育教学指导委员会副主任委员单位”“中国现代渔业职教集团理事单位和副秘书长单位”“全国水产技术推广人员培训基地”“全国水产科普教育示范基地”“山东省海洋与渔业职业教育专业建设指导委员会委员单位”“山东省专业技术人员继续教育培训基地”“山东省乡村振兴示范性职业院校”“山东省海洋与渔业实用人才培训基地”“山东省远洋渔业船员培训基地”“山东省服务外包人才培训机构”“山东省省级现代渔业技术培训基地”“山东省基层渔技人员定点培训基地”，优美的校园环境被评为“威海市花园式单位”，学院在2024年发布的“金平果”中国高职院校竞争力排行榜中，位居全国1500多所高职高专、职业本科的第193位。
    </p>
  </div>
</template>
<script>
export default {
  name: "Sub8",
  setup() {
    return {};
  }
};
</script>

<style scoped lang="less">
.body {
  width: 1440px;
  margin: 160px auto 80px;

  h1 {
    text-align: center;
  }

  .bold {
    font-weight: bold;
    font-size: 22px;
    text-indent: 2em;
  }

  p {
    text-indent: 2em;
    margin: 30px;
    color: #333;
    font-size: 18px;
    line-height: 30px;
  }

  .post {
    text-align: center;
    color: #333;
    margin: 0;
    margin-bottom: 80px;
  }

  .flex-2 {
    text-align: center;

    img {
      margin: 20px;
      margin-top: 0;
      width: 640px;
      height: 400px;
    }

    .img1 {
      margin: 40px;
      margin-top: 0;
      width: 820px;
      height: 600px;
    }

    .img2 {
      margin: 40px;
      margin-top: 0;
      width: 420px;
      height: 600px;
    }
  }

  .flex-3 {
    text-align: center;

    img {
      margin: 20px;
      margin-top: 0;
      width: 420px;
      height: 600px;
    }
  }

  .img-container {
    text-align: center;

    img {
      margin: 20px auto;
      width: 1320px;
    }
  }
}
</style>
