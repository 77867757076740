<template>
  <div class="body">
    <h1>学院简介</h1>
    <div class="img-container">
      <img src="../images/sub5/图片1.png" alt="" />
    </div>
    <p>
      山东艺术设计职业学院位于泉城济南，始建于1985年，前身为中国书画函授大学济南分校，第一任校长为我国著名国画大师于希宁先生。1990年开始，学校在聂鸿立校长的领导下，学校已成为山东省第三所独立设置的艺术类普通高校，也是全省唯一一所以应用型艺术人才培养为主要办学特色的高等职业学校。建校近40年来，学校立足自身办学特色，坚守初心使命，逐渐形成了“艺术与科技融合、传承与创新并举”的办学思路和“职教特点、艺术特色、技能特长、多元发展”的育人模式。
    </p>
    <p>
      学校目前设有创意设计学院、智慧空间设计学院、音乐舞蹈学院、数字传媒学院、美术学院、航空学院、艺术教育学院、凯文新迈尔数字艺术学院
      、继续教育学院、国际交流学院10 个二级学院，1 个思政课教学部、1
      个公共课教学部，共计
      12个教学单位。现有三年制高职专业29个，五年一贯制专业2个，涵盖文化艺术、新闻传播、装备制造、电子与信息、土木建筑、医药卫生、公共管理与服务、财经商贸、交通运输及旅游等十余个专业大类和专业类别，基本做到了传统艺术专业全覆盖，新型艺术学科有发展，先后为社会培养各类新型艺术人才40000多名，其中
      1200多人被授予“齐鲁工匠后备人才”称号，毕业生就业率连年保持在95%以上。
    </p>
    <p>
      为持续扩大办学规模，大力提高艺术职业教育质量，2023年我校济北新校区正式获批立项。济北新校区规划占地880亩，建筑面积35万平方米，是济南市重点建设项目，完全建成后可满足20000名师生的在校学习和生活。
    </p>
    <p>
      面向未来，学校将以“两高一特”
      （高水平专业、高质量就业和特色名校）建设工程为引领，紧紧围绕非物质文化遗产传承、黄河流域生态保护、新旧动能转换、数字经济赋能和乡村振兴等一系列重大课题做好发展定位，努力将学校建成“环境优美、校风优秀、条件优良、专业优质、成绩优异”的综合性文化艺术职业大学。
    </p>
    <h2>学校办学优势</h2>
    <h3>（一）办学底蕴深厚</h3>
    <p>
      学校创办近40年来，在山东省书画学会的领导与支持下，凭借其丰富的社会艺术资源和务实的办学态度，扎根齐鲁大地，传承传统艺术、创意创新现代艺术，培养了大批文化艺术专门人才。截至2021年，累计向社会输送合格毕业生4万余名，社会影响力逐步提升。1999年被列入山东省首批国家高等教育学历文凭考试试点院校，2006年成为山东省艺术类专业过程性考试院校。学校更是山东省文化创意产业职教集团副理事长单位，山东省虚拟现实职教集团副理事长单位，山东艺术职业教育专业建设指导委员会副主任委员单位，山东省“齐鲁工匠后备人才培育工
      程”选拔培养标准起草单位。
    </p>
    <div class="img-container">
      <img src="../images/sub5/图片2.png" alt="" />
    </div>
    <h3>（二）地理位置优越</h3>
    <p>
      学校黄台区位于济南市天桥区历山北路黄台山南麓，南临小清河风景带，北望黄河，东靠华山湿地，西与黄台文化城为邻，环境优美，交通便利。黄台校区占地205亩，建筑面积10万余平方米，是目前济南市仅有的一所主校区在二环以内的职业院校，极大地方便了学校的外出实践教学。
    </p>
    <div class="img-container">
      <img src="../images/sub5/图片3.png" alt="" />
      <img src="../images/sub5/图片4.png" alt="" />
    </div>
    <p>
      2023年我校济北新校区正式获批立项。济北新校区规划占地880亩，建筑面积35万平方米，是济南市重点建设项目，完全建成后可满足20000名师生的在校学习和生活。
    </p>
    <div class="img-container">
      <img src="../images/sub5/图片5.png" alt="" />
    </div>
    <h3>（三）设施条件完善</h3>
    <p>
      学校历来重视硬件条件建设，教学设备先进，教学设施齐全。多媒体教室、学术报告厅、美术馆、专家工作室、美术画廊、形体训练厅、琴房等一应俱全。学校高度重视实践教学,各专业均配备了与专业设置相对应的实习实训室和专家工作室，如“虚拟现实（VR）工程实验平台”“服装工程实验平台”“工业设计实验平台”“媒体融合实验平台”“雕塑陶瓷实验平台”“融媒体中心”“装饰材料展训室”“版画雕刻室”“动漫实训室”，等等。
    </p>
    <div class="img-container">
      <img src="../images/sub5/图片6.png" alt="" />
    </div>
    <h3>（四）“学历+若干证书”的多渠道成长成才渠道</h3>
    <p>
      为了给学生提供多样化的成才和发展途径，学校坚持育训并举、德技并修的育人理念，先后申请了“少儿硬笔书法技能证书”“空中乘务职业技能等级证书”“民航旅客地面服务职业技能等级证书”“数字影像处理”“电子商务数据分析”等20余个1+X职业技能等级证书考试试点。2023年，当年毕业生获取职业资格证书、职业技能等级证书达95%以上，有力地提高了学生的技术技能，拓宽了学生的就业创业领域。
    </p>
    <div class="img-container">
      <img src="../images/sub5/图片7.png" alt="" />
    </div>
    <h3>（五）第二实践课堂丰富多彩</h3>
    <p>
      学校始终坚持一切为了学生、为了一切学生、为了学生一切的服务理念，为学生提供了优良的、立体化的生活和学习环境。支持第二课堂实践与第一课堂教学有机结合，支持学术型社团和专业实践社团建设。如篮球社、羽毛球社、乒乓球社、足球社、轮滑协会、大学生记者团、文学社、吉他协会、书画协会、摄影协会、演讲与口才协会、武术协会，等等。
    </p>
    <div class="img-container">
      <img src="../images/sub5/图片8.png" alt="" />
    </div>
    <h3>（六）师资力量雄厚</h3>
    <p>
      学校高度重视师资梯队建设。经过多年努力，现已形成了一支以专为主、专兼结合，数量充足、素质过硬的专兼职教师队伍。以“专家治校、名师执教”为引领，重点建设了一个政治上过硬、专业上突出、社会影响大的学科专家和艺术名流于一体的学术委员会；由省内外高等院校资深教授、行业精英担当的学科带头人；由校内拥有双证书，校企合作单位和行业协会等兼职教师组成的“双师型”队伍。学校还大力引进国内外知名学府的年轻教师，如中国艺术研究院、中国音乐学院、东华大学、山东师范大学、山东工艺美术学院、山东艺术学院等教师，全方位构筑学校人才库。
    </p>
    <div class="img-container">
      <img src="../images/sub5/图片9.png" alt="" />
    </div>
    <h3>（七）教学成果显著</h3>
    <p>
      学校坚持“让艺术家的卓越成就得以传承，让艺术学子的人生梦想得以实现”的人才培养理念，实行导师责任制，学生按照“双向选择，自愿组合”的原则进入专家工作室学习、创作，实现了教学生产化和生产教学化的有机结合。近两年第7届全国青年美展、第12届和13届全国美展，山东省各类美术、书法、设计、声乐、舞蹈大赛等参展、获奖的我校师生倍增。
    </p>
    <div class="img-container">
      <img src="../images/sub5/图片10.png" alt="" />
    </div>
    <h3>（八）品牌建设卓著</h3>
    <p>
      学校一直践行“艺术与科技融合、传统与创新并举”的办学理念，以服务省市发展战略为依托，深入实施专业集团化办学，精心打造书画艺术、创新创意艺术设计、音乐舞蹈、数字媒体艺术、航空乘务5个专业群，同时与北京凯文教育集团等企业共建艺术产业学院，2022年均开始招生。
    </p>
    <h3>（九）拓宽学生学历提升渠道</h3>
    <p>
      学校积极落实教育部纵向贯通、横向融通的办学导向，开设了专升本特色创新培养班，与省内多家知名培训学校签订合作协议，全方位助力在校生直通本科高校，完成学生的升本梦；通过产教融合、校企合作，动态调整人才培养方案；开展同类课程置换、跨专业学分互认、重点课程突破等方法，确保提升渠道畅通；通过与国外高校合作，修业年限内学分互认，支持、鼓励学生到国外留学深造。
    </p>
    <h3>（十）毕业生就业创业服务体系健全</h3>
    <p>
      学校与各二级学院专门设置了大学生就业与创业服务机构，通过职业生涯规划、就业培训、推荐单位等举措全方位提升了就业率和就业质量，增强了学生创业本领。近年来，我校毕业生就业率一直保持在95%以上，用人单位满意度高；毕业生创业率连续多年保持在5%以上，位居全省高校前列，吸引了越来越多的莘莘学子来我校学习。
    </p>
    <div class="img-container">
      <img src="../images/sub5/图片11.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Sub5"
};
</script>

<style scoped lang="less">
.body {
  width: 1440px;
  margin: 160px auto 80px;

  h1 {
    text-align: center;
  }
  h3 {
    margin: 30px 0;
  }

  p {
    text-indent: 2em;
    margin: 30px;
    color: #333;
    font-size: 18px;
    line-height: 30px;
  }

  .img-container {
    text-align: center;

    img {
      margin: 20px auto;
      width: 1320px;
    }
  }
}
</style>
