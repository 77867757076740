<template>
  <div class="body">
    <h1>学校简介</h1>
    <div class="img-container">
      <img src="../images/sub3/图片1.png" alt="" />
    </div>
    <p>
      湖南幼儿师范高等专科学校是2013年经省人民政府批准、教育部备案的全日制公办普通高等学校。学校由原常德师范学校和桃源师范学校合并升格而成，原桃源师范学校由著名民主革命家宋教仁于1912年倡导创立，原常德师范学校始创于1947年。
    </p>
    <p>
      学校位于长江经济带、长江中游城市群、环洞庭湖生态经济圈的重要城市之一——常德市。史称“川黔咽喉、云贵门户”的常德，古称武陵，是一座拥有二千年历史的文化名城，是国家公路运输枢纽城市、湘西北铁路枢纽城市，先后荣获全国文明城市、中国优秀旅游城市、国家卫生城市、国家园林城市、中国首届魅力城市、国际湿地城市、国际花园城市、全国交通管理模范城市、国家环境保护模范城市、中华诗词之市等称号。
    </p>
    <p>
      学校是国家教委表彰的全国先进师范、全国职业教育先进单位、全国幼教舞蹈素质与能力培训项目优秀培训基地、国家教育部第二批1+X证书制度试点院校和湖南省现代学徒制试点单位、省小学和幼儿园教师培养基地、省教育科学学前教育研究基地、省语言文字推广基地、省级文明标兵校园、省级平安高校。学校和日本琵琶湖学院大学、韩国启明大学是友好学校。百年来，学校为社会输送各类人才10多万名，作家丁玲、诗人未央、教育家王一知、原副省长李友志等都是校友中的杰出代表。
    </p>
    <p>
      学校位于常德市职教大学城，占地549亩，新校区规划占地271亩，建筑面积19万多平方米，新校区规划建筑面积10万多平方米。设备仪器总值1.5亿元，馆藏图书108万余册，建有专业实验实训室及专业教室共93间，教学琴房367间，多媒体、语音、科学等教学设施一应俱全。现已形成以教育学为主，艺术学、工学和管理学共同发展的学科体系。目前，开设有小学教育、学前教育、早期教育、体育教育、艺术教育、现代教育技术、小学科学教育、小学数学教育、小学道德与法治教育、小学语文教育、小学英语教育、音乐教育、美术教育、舞蹈教育、表演艺术、环境艺术设计、视觉传达设计、婴幼儿托育服务与管理、计算机应用技术、软件技术、动漫制作技术、人工智能技术应用、旅游管理、研学旅行管理与服务、社会体育等25个大专层次专业。“乡村小学教师教育”和“学前教师教育”专业群2018年入选湖南省高等职业教育一流特色专业群建设项目，“学前教育”和“艺术教育”专业群2022年入选湖南省楚怡高水平高职建设计划建设单位A档。形成的高素质专业化幼儿教师培养体系与复合型高素质乡村小学教师培养“湖南模式”，分获2021年湖南省职业教育教学成果奖一等奖和特等奖，专业办学综合实力首屈一指。
    </p>
    <p>
      学校面向14省市招生，高招录取分数线一直位居全省高职高专院校前列。有全日制在校生9500多人，有教职工610名，其中教授及副教授151人、博士及在读博士20人、硕士304人，“双师型”教师269人。近年来，教师主编（参编）教材172部，其中十四五职业教育国家规划教材5部，省级优质、优秀教材10部。出版著作11部。在核心及以上期刊发表高质量论文16篇。课题获省级及以上项目97项，其中国家社科基金项目2项，全国教育规划教育部青年项目2项。师生参加职业技能竞赛，获国家级奖项3项，省级一等奖19项，二等奖32项，三等奖56项；参加其他学科类竞赛，获国家级奖项7项，省级奖项309项。
    </p>
    <p>
      百年师范，万世师表。学校以培养专科层次的小学和幼儿园教师、培训常德市在职小学和幼儿园教师为己任，以立足湘西北、面向全省、辐射全国的办学思路，力争建成全省领先、全国一流的幼儿师范高等专科学校。
    </p>
  </div>
</template>
<script>
export default {
  name: "Sub3"
};
</script>

<style scoped lang="less">
.body {
  width: 1440px;
  margin: 160px auto 80px;

  h1 {
    text-align: center;
  }

  .bold {
    font-weight: bold;
    font-size: 22px;
    text-indent: 2em;
  }

  p {
    text-indent: 2em;
    margin: 30px;
    color: #333;
    font-size: 18px;
    line-height: 30px;
  }

  .flex-2 {
    text-align: center;

    img {
      margin: 40px;
      margin-top: 0;
      width: 620px;
      height: 500px;
    }
  }

  .img-container {
    text-align: center;

    img {
      margin: 20px auto;
      width: 1320px;
    }
  }
}
</style>
