<template>
  <div class="body">
    <h1>学院简介</h1>
    <p>
      郑州信息科技职业学院是2002年由河南省政府批准成立、2003年在教育部备案的一所专科层次的全日制普通高职院校，实行省政府主管、河南开放大学代管的管理体制，有效利用河南开放大学优质资源办学，两校实行“一套机构、两块牌子、统一领导、一体运作”的运行模式。
    </p>
    <p>
      学校位于郑州市郑东新区龙子湖高校园区，占地总面积500亩，建筑面积35万平方米，教学科研仪器设备总值10291.65万余元，图书62.5万余册。现有教职工702人，全日制在校生1.07万人，高职扩招在校生4291人。累计培养合格毕业生4.5万余人，近5年就业率稳定在95%以上。学校设有信息工程与人工智能学院，机电工程与智能制造学院，建筑工程与智能建造学院，人文与设计学院，工商与财会学院，艺术与体育学院，马克思主义学院，乡村振兴学院、特殊教育学院，智慧文旅学院等9个学院；开设有大数据技术、工业机器人技术、电子商务、大数据与会计等32个专业；设有润心、文心、匠心三个书院，建立了“五育并举”和“三全育人”协同体制机制，形成了“学校—书院—育人导师—社区—宿舍”五级管理体系和“一站式”学生社区体系，办学治校和育人工作水平持续提升。
    </p>
    <p>
      学校秉承“励志图强、与时俱进”的校训，坚持社会主义办学方向，全面贯彻党和国家的教育方针政策，以职业能力提升为导向，以培养高素质技能型人才为目标，全力推进校企合作、工学结合，建立健全校企协同育人长效机制，入选全国第一批职业院校数字校园建设试点院校、教育部现代学徒制试点、全国“1+X”证书制度试点院校，成立了河南省人工智能职业教育集团、鲲鹏产业学院和智慧文旅行业学院，获批人工智能技术及应用、工程智能绿色建造等5个省级工程中心，获批省现代学徒制示范点立项建设单位、省深化创新创业教育改革示范高校、省特色行业学院建设单位、省职业教育示范性校企合作项目、省职业教育示范性虚拟仿真实训基地。2019年以来，学校主动响应党中央、国务院高职扩招百万的号召，利用河南开放大学系统力量，积极参与高职扩招工作，打造“学生招在郑信、面授放在就近、线上线下结合、校企多元实训”的办学模式，育人效果良好。学校成功获批建设博士后科研工作站分站，科研水平和人才培养质量不断提高；师生在各类竞赛中获得国家级奖项60余项、省级奖项近500项，在“互联网+”大学生创新创业大赛、全国职业学校技能大赛等多个国家级比赛中取得重大突破。学校先后被授予“河南省职业教育品牌示范院校”“河南省省级优质高等职业院校”称号，获批河南省高等职业学校高水平专业建设学校，多项改革成果被人民日报、中国教育报、河南日报、河南电视台等媒体报道。
    </p>
    <p>
      学校将坚持以信息技术应用为支撑，素质、技能并重，推进学历教育与学历、非学历继续教育衔接，多专业、多层次综合协调发展，致力于建设成为“省内一流，特色鲜明”的高水平职业院校，为区域经济社会发展作出更大的贡献。
    </p>
  </div>
</template>
<script>
export default {
  name: "Sub4"
};
</script>

<style scoped lang="less">
.body {
  width: 1440px;
  margin: 160px auto 80px;

  h1 {
    text-align: center;
  }

  .bold {
    font-weight: bold;
    font-size: 22px;
    text-indent: 2em;
  }

  p {
    text-indent: 2em;
    margin: 30px;
    color: #333;
    font-size: 18px;
    line-height: 30px;
  }

  .flex-2 {
    text-align: center;

    img {
      margin: 40px;
      margin-top: 0;
      width: 620px;
      height: 500px;
    }
  }

  .flex-3 {
    text-align: center;

    img {
      margin: 20px;
      margin-top: 0;
      width: 420px;
    }
  }

  .img-container {
    text-align: center;

    img {
      margin: 20px auto;
      width: 1320px;
    }
  }
}
</style>
