<template>
  <div class="body">
    <h1>学院简介</h1>
    <p>
      湖南高速铁路职业技术学院前身为衡阳铁路工程学校，1951年由铁道部创办。2004年划归市委、市政府管理，2005年升格为湖南交通工程职业技术学院，2011年更名为湖南高速铁路职业技术学院，2014年整体搬迁到酃湖高铁新城。学院是湖南省“楚怡”高水平高职学校、省卓越高职院校、省文明标兵校园、省深化新时代教育评价改革试点高校，拥有国家级职教集团、众创空间、产学研合作创新示范基地、创新中心、就业创业创新实践示范基地、专精特新产业学院、首批数智化标杆实训基地等10多个国家级专业创新和技术服务平台。办学73年来，为中国铁路培养了近10万“铁军”，被誉为中国铁路的黄埔军校、站段长的摇篮。
    </p>
    <p>
      学院占地面积850多亩，建筑面积近36万㎡，建设总投入15亿多元。设有7个二级学院，34个专科专业和1个本科专业。拥有省高水平专业群3个、省级一流专业群3个，服务“三高四新”战略高水平专业群1个，构建了与高铁、轨道交通行业和区域经济发展相适应、特色鲜明的专业格局。
    </p>
    <p>
      学院现有教职员工近千人，在职在岗近700人。其中，硕士以上学位教师370人，高级职称222人，“双师素质”教师占比达到80.6%。2名教师分别获得全国“五一”劳动奖章和“全国优秀教师”，拥有湖南“芙蓉”名师1人、全国“双师型”技术技能产业导师2名，省级及以上教学名师26名，铁道部“火车头奖章”获得者3名，全国行指委委员6人，省级专业教学团队3个，湖南省中青年骨干教师18名。学院还聘请了5名领军人才、11位产业导师、9位企业技能大师为顾问，建立了20余个技能大师工作室。
    </p>
    <p>
      学院面向全国27个省市招生，现有全日制在校生14000余人。近两年，学生参加各级各类竞赛获奖210余项，其中国赛获奖10余项。毕业生主要面向国铁集团、地铁公司、中铁建、中铁工等企业高质量就业，近三年毕业生就业率均达96%以上。连续6年获湖南省招生工作先进单位，连续5年获湖南省就业工作先进单位，连续两次获评湖南省就业创业“一把手工程”优秀单位，社会服务贡献连年进入全省高职院校20强。
    </p>
    <p>
      学院坚持走产教融合、校企合作之路，校企合作共建产业学院3个。拥有湖南省工程研究中心1个，综合实训基地5个、实训室150多个和稳定的校外实习实训基地300多个，是湖南省国际科技创新合作基地、省科学技术普及基地、省社科普及基地、省“楚怡”文化传承基地、省“楚怡”产教融合实训基地。牵头的南方高铁人才培养与技术合作联盟是国家级示范性职业教育集团、湖南省“楚怡”示范性职教集团。
    </p>
    <p>
      学院积极对接“一带一路”倡议和助力中国高铁“走出去”战略，在泰国哇碧巴通职业技术学院挂牌成立“天佑铁路学院”泰国分院，泰国高铁职教合作项目入选教育部“中国-东盟双百职校强强合作旗舰计划”首批20个之一。与“赢联盟”、几内亚科纳克里大学共同推进培养本土化铁路建设人才，成立本科层次“天佑”铁路学院。中几铁路国际人才订单式培养项目入选中国教育部首批“未来非洲—中几职业教育合作特色项目”，成为湖南省中非合作闪亮名片。国际交流办学案例入选国家职业教育质量年报。2023年学历和非学历继续教育（培训）收入达1319.919万元，完成3万人次的培训量。进入国家2023年职业教育质量年报中产教融合卓越榜单50强。
    </p>
    <p>
      目前，学院正积极发挥行业背景深厚、地方政府和兄弟院校鼎力支持的多重优势，齐心协力促改革，聚精会神谋发展，持续提高办学水平和人才培养质量，推动国家“双高”职院和“全国文明校园”创建工作，为打造“行业领先、特色鲜明、国内一流、世界知名”本科层次高等职业院校，更好地服务国家战略、服务高铁及轨道交通产业，更好地促进区域经济社会发展，提供高素质技术技能人才支撑。
    </p>
  </div>
</template>
<script>
export default {
  name: "Sub10"
};
</script>

<style scoped lang="less">
.body {
  width: 1440px;
  margin: 160px auto 80px;

  h1 {
    text-align: center;
  }

  .bold {
    font-weight: bold;
    font-size: 22px;
    text-indent: 2em;
  }

  p {
    text-indent: 2em;
    margin: 30px;
    color: #333;
    font-size: 18px;
    line-height: 30px;
  }

  .flex-2 {
    text-align: center;

    img {
      margin: 40px;
      margin-top: 0;
      width: 620px;
      height: 500px;
    }
  }

  .img-container {
    text-align: center;

    img {
      margin: 20px auto;
      width: 1320px;
    }
  }
}
</style>
