<template>
  <div class="body">
    <h1>济宁职业技术学院简介</h1>
    <p>
      济宁职业技术学院坐落在孔孟之乡、运河之都、全国文明城市——山东省济宁市，是2000年10月经山东省人民政府批准成立的公办高等职业院校。学院是山东省首批技能型人才培养特色名校、山东省优质高等职业院校、省级文明校园，获批全国首批职业院校文化素质教育基地，入选中央电视台“职业院校百强巡礼”，被中央文明委授予全国文明单位。
    </p>
    <p>
      学院现有专兼职教职工1400余人，专职教师中博士、硕士占比85%以上，在校生22000余人；拥有二级教授5人、全国劳动模范1人、国务院特殊津贴专家1人、省级突贡专家3人、省级教学名师9人、齐鲁文化英才1人。以企业工程师、技师为主体的兼职教师库610余人；拥有国家级教学团队1个，全国技术能手1人，省教学团队8个，省黄大年式教师团队3个，省级名师工作室2个，省级教学创新团队3个，省技艺技能传承创新平台5个，省技术能手5人。
    </p>
    <p>
      学院现有16个教学科研单位及20个教辅单位，开设16大类55个专业，建成了新一代信息技术、智能制造、医养健康、文化创意、“互联网+”商务、智慧建造、精品旅游、学前教育等紧密契合新旧动能转换工程的10个优势专业群。学院获批国家和省级现代学徒制试点专业、品牌专业群、高水平专业群等专业建设项目55项，拥有与临沂大学、济宁学院联合培养的汽车检测与维修技术、软件技术2个“3+2”专本贯通专业；眼视光技术专业在全国同类院校专业排名位列前列，软件技术专业成功入选教育部中德先进职业教育项目，文化创意专业集群入选教育部“中外人文交流全媒体产教融合项目”。
    </p>
    <p>
      学院拥有国家和省级专业教学资源库2个，国家和省级精品课程和精品资源共享课59门，教育部1+X证书试点50个。承担国家、省、市及横向教科研项目490项，荣获国家和省市级教科研成果奖420项，其中国家级教学成果奖一等奖1项、二等奖2项，山东省教学成果奖特等奖3项、一等奖9项，山东省政府文化创新奖3项。拥有机电、数控、汽车等47个校内实训中心、3家校中厂，设立400余家校外实训基地，获批国家级示范性虚拟仿真实训基地培育项目、山东省大数据人才实训基地、山东省新旧动能转换公共实训基地。拥有23个部、省、市级创新平台。
    </p>
    <p>
      建院以来，学院以“立德树人”为根本任务，秉持“崇德尚能”的校训、“敬事而信”的校风、“诲人不倦”的教风、“学而时习”的学风，以重点专业建设为平台，以体制机制创新为突破，以培养模式改革为主线，抓改革、促创新、求突破，朝着“学生光彩、企业首选、家长满意、社会信任”的目标迈进，走出了一条“产教互融、校企共同、学岗直通、文化育人”的特色办学之路。学生在技能大赛、文体竞赛等国赛、省赛共获奖700余项，1个团支部荣获“全国五四红旗团支部”，3个团支部当选全国高校“活力团支部”，学院女排荣获全国高职院校大学生排球联赛亚军，省赛8次冠军，女篮荣获全省一等奖。
    </p>
    <p>
      学院推进以文化人、以文育人，构建文化育人格局，丰富文化育人内涵，彰显文化育人特色。创新“三融三进三课堂”文化育人体系，首创“生态树型”文化育人成长模型。构建文化专门课、文化浸入课、文化活动课和文化习养课在内的“四位一体”文化育人课程体系，实现了文化育人的课程化、学分化、制度化。创新三梯度体验式教学模式。文化育人团队荣获山东省黄大年式优秀教学科研团队称号。开发建设的《儒家文化与鲁班工匠精神传承与创新》国家级教学资源库，获批教育部网络思政育人共同体项目，获评2021年全国“终身学习品牌项目”。《高职院校“三融三进三课堂”文化育人体系研究与实践》获得2018年国家教学成果一等奖；《微指尖
      大思政——高职院校“五位一体”网络思政育人体系构建与实施》获2022年山东省教学成果特等奖；先后荣获第二届、第四届、第六届山东省人民政府文化创新奖。
    </p>
    <p>
      学院注重吸纳国外先进教育理念和先进教育标准，积极推进与俄罗斯远东国立交通大学、韩国乙支大学、韩国湖南大学的国际办学合作，开设中外合作办学专业5个。牵头成立“视光国际职教集团”，建成9个海外实习实训基地。与德国工商业行会、德国手工业行会开展合作。学院入选第二批“坦桑尼亚国家职业标准开发项目”立项建设单位，获批教育部中外语言交流合作中心2022“汉语桥”线上团组项目。
    </p>
    <p>
      进入“十四五”，学院以习近平新时代中国特色社会主义思想为指导，认真贯彻落实习近平总书记对于职业教育工作的重要指示和全国职业教育大会精神，坚持党的领导，全面立德树人，全力推进创一流、创双高、创高职本科，辐射华东，面向全国，坚定不移把学院打造成为高层次技术技能人才培养基地和科技研发服务基地，朝着创建本科职业技术大学的奋斗目标迈进。
    </p>
  </div>
</template>
<script>
export default {
  name: "Sub9"
};
</script>

<style scoped lang="less">
.body {
  width: 1440px;
  margin: 160px auto 80px;

  h1 {
    text-align: center;
  }

  .bold {
    font-weight: bold;
    font-size: 22px;
    text-indent: 2em;
  }

  p {
    text-indent: 2em;
    margin: 30px;
    color: #333;
    font-size: 18px;
    line-height: 30px;
  }

  .post {
    text-align: center;
    color: #333;
    margin: 0;
    font-weight: bold;
    margin-bottom: 80px;
  }

  .flex-2 {
    text-align: center;

    img {
      margin: 20px;
      margin-top: 0;
      width: 640px;
      height: 400px;
    }
    .img1 {
      margin: 40px;
      margin-top: 0;
      width: 820px;
      height: 600px;
    }

    .img2 {
      margin: 40px;
      margin-top: 0;
      width: 420px;
      height: 600px;
    }
  }

  .flex-3 {
    text-align: center;

    img {
      margin: 20px;
      margin-top: 0;
      width: 420px;
      height: 600px;
    }
  }

  .img-container {
    text-align: center;

    img {
      margin: 20px auto;
      width: 1320px;
    }
  }
}
</style>
