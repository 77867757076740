<template>
  <div class="body">
    <h1>学校简介</h1>
    <p>
      益阳职业技术学院是2004年经省政府批准、教育部备案，由益阳市人民政府举办的全日制公办高等职业院校（教育部备案编号：13808，在湘招生代码：4743）。前身是1972年建校的国家级重点中专原益阳地区农校和1979年建校的省部级重点中专原益阳地区供销（工贸）学校。2006年6月整体搬入现址（原益阳师专校址）。2016年8月，市政府将益阳商务电子职业中等专业学校划归学校管理。学校紧临迎风桥交通枢纽长张高速出口处，交通便利，环境优美。
    </p>
    <p>
      校园面积636.29亩（其中高新产业学院104.43亩），建筑面积17.6万多平方米。在岗教职员工492人(不含合作办学单位136人)，其中教授17人，副教授职称87人，博士及博士后4人，硕士研究生174人。有湖南省芙蓉计划——湖湘青年英才项目2人、湖南省科技人才托举工程项目1人，益阳市小荷人才1人。有湖南“楚怡”教学名师2人，湖南省农业职教名师2人，省级中青年骨干教师及培养对象20人，国内访问学者8人，双师型教师207人，有国家级非遗制作技艺大师2人。开设28个专业，现有在校学生10938人。设有11个教学机构、4个教辅机构、10个党政管理机构、2个群团组织。有船舶、奇瑞汽车、汽车检测与维修、工业机器人、财会、电商、农产品检测、园林花卉培植、食用菌生产等100多个实习实训基地。有3个大师工作室、若干个教师工作站。
    </p>
    <p>
      学校是湖南省楚怡“双高”建设单位，先后被评为全国德育工作先进单位、全国民族团结进步示范单位；湖南省职业教育与成人教育先进单位、民族团结进步示范单位、职业技能鉴定质量诚信先进单位、就业创业工作“一把手工程”优秀单位；是全国数字化财经产教融合共同体理事单位、全国数智财税行业产教融合共同体副理事长单位、全国智慧农业产教融合共同体副理事长单位、全国新能源与智能网联汽车智慧流通产教融合共同体副理事长单位、全国数字商务行业产教融合共同体副理事长单位、全国医药装备制造行业产教融合共同体副理事长单位、全国新一代自主安全计算机系统产教融合共同体成员单位、湖南省农村电商产教联盟理事长单位、湖南船舶工业协会副会长兼秘书长单位、湖南省汽车行业协会副会长单位、湖南省电子电路行业协会副会长单位、中非经贸合作职业教育产教联盟常务理事单位；是湖南省一流特色专业群建设院校、乡村振兴人才培养优质校、“现代学徒制”和“1+x”证书试点院校；是企业人才示范培训基地，船舶焊接、电商、汽车培训基地，全市党员教育、移民、中职教师、新型职业农民培训基地；是湖南省平安建设示范校、绿色学校创建示范单位、文明高等学校、节水型高校、最美高校。
    </p>
    <p>
      学校以建设特色鲜明的高水平高职学校为目标，坚持“质量立校、人才兴校、品牌强校”办学战略和“以学生为中心”办学理念，秉承“明德、笃学、精技、创新”的校训和“奋楫争先，扬帆致远”的学校精神，坚持立德树人，培养能工巧匠和大国工匠的办学使命，强化“三全育人”，实施“铸魂工程”，促进学生德、智、体、美、劳全面发展，培养高素质技术技能型人才。
    </p>
    <p>
      <span style="font-weight: bold;">学校办学特色鲜明。</span
      >有教育部认定国家骨干专业1个、中央财政支持重点建设专业1个、省级一流特色专业群1个、省级精品专业1个。船舶工程技术、农村电子商务、智慧农业三大专业群入围湖南省楚怡高水平专业群。船舶工程技术专业群是湖南省首批高职一流特色专业群建设项目。坚持开放办学，走产教融合、校企合作之路，与艾华集团、奥士康、艾迪奥电子、三一中阳、湘船重工、口味王、亚光科技、伟源科技、金博碳素、维仕科技、华为、拓维、湖南开鸿智谷、楚天科技、信维声学、银鱼农业、奇瑞汽车、金蝶集团、等企业建立了较为稳定的合作关系。与益阳国家级高新区共建高新产业学院、中南电商学院。与益阳高新区管委会合作，申请地方政府债券1.4亿元，新建高新产业学院校区。与相关企业合作共建华为云、建设工程管理学院。
    </p>
    <p>
      <span style="font-weight: bold;">学校办学质量显著提升。</span
      >是全省高职院校焊接技术赛项赛点、船舶主机和轴系安装赛点和市级中职学校竞赛赛点学校。近两年，学生参加各类技能竞赛，获得全国性一等奖11个、二等奖9个、三等奖6个；省赛一等奖31个、二等奖71个。教师参加各类职业能力和教学能力竞赛，获国赛（行业赛）一等奖2个、二等奖、三等奖各3个，获省赛一等奖6个、二等奖15个、三等奖25个，获湖南省普通高校就业创业理论研究征文活动一等奖1个。近几年，教师科研获省厅级立项190余项，公开发表论文1200余篇。有省级专业教学资源库项目3个，省级精品在线课程7门，省级优秀教材4部，省级专业教学团队1个。电子商务专业获评湖南省专业论证优秀等次。
    </p>
    <p>
      <span style="font-weight: bold;">学校办学影响持续扩大。</span
      >近年来，学校建成“铸牢中华民族共同体意识实践基地”，荣获湖南省“5·8人道公益日爱心单位”，学工团队荣获全国妇联“巾帼文明岗”。“百师进百村”助力乡村振兴活动入选了全省高校参与脱贫攻坚“十大典型案例”、第五届省属高等院校精准帮扶典型项目。船舶智造产教融合实训基地立项湖南省“楚怡”产教融合实训基地，湖南省农村电商产教联盟立项湖南省“楚怡”示范性职业教育集团（联盟）。完成湖南省职业教育“楚怡”重点攻关项目。立项教育部高校学生司第二期供需对接就业育人项目3项。立项省级现代职业教育体系建设重点项目13项。《税收管理与筹划工作手册》入选首批“十四五”职业教育国家规划教材。立项“楚怡”行动高水平教师队伍建设项目5项。获评2022年度湖南省“湖湘工匠”培育和竞赛基地、“2022年湖南省普通高校大学生创新创业孵化示范基地”。
    </p>
    <p>
      船舶与机电工程学院学生支部被评为“全国高校党建样板支部”和“第二批全省高校党建样板支部”，党总支被评为“湖南省高校党建标杆院系”。经济管理学院立项全省高校“三全育人”综合改革试点院系、党总支被评为“湖南省高校党建标杆院系”、团总支荣获“全国五四红旗团总支”“湖南省青年文明号”等荣誉称号。
    </p>
  </div>
</template>
<script>
export default {
  name: "Sub1"
};
</script>

<style scoped lang="less">
.body {
  width: 1440px;
  margin: 160px auto 80px;

  h1 {
    text-align: center;
  }

  .bold {
    font-weight: bold;
    font-size: 22px;
    text-indent: 2em;
  }

  p {
    text-indent: 2em;
    margin: 30px;
    color: #333;
    font-size: 18px;
    line-height: 30px;
  }

  .flex-2 {
    text-align: center;

    img {
      margin: 40px;
      margin-top: 0;
      width: 550px;
      height: 800px;
    }
  }

  .img-container {
    text-align: center;

    img {
      margin: 20px auto;
      width: 1320px;
    }
  }
}
</style>
