<template>
  <div class="body">
    <h1>学校简介</h1>
    <div class="img-container">
      <img src="../images/sub11/图片1.png" alt="" />
    </div>
    <p>
      安阳幼儿师范高等专科学校坐落于中国历史记载最早都城、中国八大古都之一、甲骨文的故乡、周易的发源地、红旗渠精神的发祥地、精忠报国岳飞的家乡河南省安阳市，是经河南省人民政府批准、教育部备案设立的一所全日制公办市属普通高等专科学校。
    </p>
    <p>
      学校始建于1978年，前身为安阳市师范学校。1988年更名为安阳市第一师范学校，2002年更名为安阳幼儿师范学校，2012年2月在全省首批升格为幼儿师范高等专科学校。学校现有占地面积500.4亩(另规划新校区1000余亩)，总建筑面积22.6万余平方米，图书馆藏书94.08万册，教学仪器设备总价值约6200万元，校外实习实训基地72个，附属省级示范幼儿园2所。
    </p>
    <p>
      学校全面贯彻党的教育方针，坚持社会主义办学方向，“立足安阳,服务河南，辐射全国”，是豫北地区幼儿教师培养中心、在职幼儿园教师继续教育中心、幼儿教育科学研究中心、社区早期教育推广服务中心。学校现有全日制在校生近11000人，生源涵盖全国13个省市。现有教职工522人，其中专任教师496人、副高级以上专业技术职务教师90人,博士在读教师30人,研究生以上学历和博士硕士学位教师占比53%。学校办学条件优越，基础设施齐全，配备有幼儿园保育实训室、亲子活动实训室、蒙氏教育实训室、感觉统合实训室、奥尔夫音乐教学实训室、幼儿园区角活动实训室、玩教具制作和环境创设实训室、积木搭建游戏室、模拟教学实训室、语音教室、“双师互动”教室、多功能排练室、教学资源制作中心、微课制作室、体艺中心等校内教学或实训场馆。
    </p>
    <p>
      学校坚持以人为本办学理念，落实立德树人根本任务，形成了以“学前教育为引领、艺术教育为特色、儿童发展相关专业为支撑、社会服务类专业为补充”的专业体系，开设有学前教育、早期教育、音乐教育、小学语文教育、小学英语教育、美术教育、体育教育、小学教育（全科）、小学道德与法治教育、音乐表演、舞蹈表演、社会体育、建筑室内设计、数字媒体技术、空中乘务、工业机器人技术、视觉传达设计、航空安全技术、中文、网络营销与直播电商等20个专业。学校与美国汉弗莱斯大学合作举办国际合作办学项目，开设有学前教育专业。此外，学校与德国学前教育学院、韩国翰林圣心大学、泰国格乐大学、泰国曼谷吞武里大学、泰国商会大学、澳大利亚伍伦贡马来西亚分校、马来西亚阿尔法大学学院等院校先后开展了形式多样的文化交流与合作。
    </p>
    <p>
      近年来，学校生源质量稳步提高，教育教学成绩斐然。学前教育、小学语文教育、小学英语教育、音乐教育、美术教育、体育教育等6个专业被教育部列入全国师范生免试认定中小学教师资格改革试点。学前教育专业被教育部认定为高等职业教育骨干专业。学校是河南省职业教育学前教育专业教学资源立项建设单位，1门课程被认定为职业教育国家在线精品课程，2门课程被认定为河南省职业教育精品在线开放课程，获批河南省职业教育精品在线开放课程立项建设5项、河南省职业院校“双师型”名师工作室1个、河南省1+X证书制度试点项目4项。学校积极推进“人人持证，技能河南”建设，校职业技能等级认定中心先后取得育婴员、保育师、讲解员和营养配餐员等四个职业认定资格。2023年学校专升本上线人数1149人，6名学生分别取得学前教育、教育学、心理学专业河南省前10名的好成绩，其中2名学生取得教育学专业和心理学专业全省第一名的佳绩。
    </p>
    <p>
      建校45年来，学校秉承“明德厚艺，贤行润身”的校训，为社会培养培训了5万多名毕业生。一批优秀学生先后被评为全国模范教师、省市优秀教师，学校被誉为豫北小学、幼儿教师的摇篮。学校相继获得了国家级语言文字规范化示范校、河南省文明校园标兵、省级卫生先进单位、河南省首批基础教育教师培训基地、河南省第二批教师教育改革创新实验区、河南省高校“三全育人”综合改革试点单位、河南省职业教育特色院校、省级节约型公共机构示范单位、河南省教育系统网络安全和信息化工作先进集体、河南高校宣传先进单位等荣誉称号。
    </p>
    <p>
      站位新时代，扬帆正当时。今天的安阳幼儿师范高等专科学校，正高举中国特色社会主义伟大旗帜，以习近平新时代中国特色社会主义思想为统领，深入贯彻党的二十大精神，落实立德树人根本任务，坚守教师教育主责主业，紧密围绕高质量发展和特色发展两条主线，坚定升格本科院校目标任务，全面提升人才培养质量，努力将学校建成高水平、有特色、综合化、全国一流幼儿师范院校。
    </p>
  </div>
</template>
<script>
export default {
  name: "Sub11"
};
</script>

<style scoped lang="less">
.body {
  width: 1440px;
  margin: 160px auto 80px;

  h1 {
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  p {
    text-indent: 2em;
    margin: 30px;
    color: #333;
    font-size: 18px;
    line-height: 30px;
  }

  .img-container {
    text-align: center;

    img {
      margin: 40px auto;
      width: 1320px;
    }
  }
}
</style>
