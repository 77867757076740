<template>
  <div class="body">
    <h1>学校简介</h1>
    <div class="img-container">
      <img src="../images/sub12/图片1.png" alt="" />
    </div>
    <p>
      聊城职业技术学院是2000年10月经山东省人民政府批准成立的全日制公办学校，是山东省技能型人才培养特色名校、山东省首批示范性高职院校、山东省优质高等职业院校，是聊城市政府建设聊城现代职业教育体系的龙头单位。
    </p>
    <p class="emphsis">学校概况</p>
    <p>
      学校坐落于聊城市区北部。聊城，被誉为“江北水城，两河明珠”，位于山东省西部、冀鲁豫三省交界处，总面积8628平方公里，是国家历史文化名城、中国优秀旅游城市、国家卫生城市。
    </p>
    <p>
      学校由南北两个校区组成，总建筑面积45万平方米。南校区底蕴厚重、端庄典雅；北校区时尚现代、活力四射。设施完善、功能齐全、书香氤氲的智慧化校园，为培养德智体美劳全面发展的高素质技术技能人才提供了理想的场所。
    </p>
    <div class="img-container">
      <img src="../images/sub12/图片2.png" alt="" />
    </div>
    <p>
      学校设有护理系、医学系、机电工程系、经济管理系、信息工程系、建筑工程系、旅游管理系、农牧科技系、应急管理学院、马克思主义学院、基础部等11个系(院）部，设有42个专科招生专业，招生范围覆盖山东、山西、河南、安徽、江苏、宁夏、吉林、甘肃、内蒙古、贵州等10个省市自治区。现有全日制在校生1.8万余人，专兼职教师一千余人。
    </p>
    <p class="emphsis">党建引领 立德树人</p>
    <p>
      学校党委坚持以习近平新时代中国特色社会主义思想凝心铸魂，坚守立德树人初心使命，落实“五育并举”要求，以劳模精神、劳动精神、工匠精神筑牢成长根基，擦亮“信仰红、工匠蓝、水城绿”育人品牌，不断完善大思政育人新体系，培养更多德智体美劳全面发展的社会主义建设者和接班人。
    </p>
    <div class="img-container">
      <img src="../images/sub12/图片3.png" alt="" />
    </div>
    <p>
      学校成立孔繁森精神与红色文化研究院、黄河研究院、中华家风研究院，打造“四馆三院一路”育人平台。成立法治文化讲习所实施“法润聊职”工程；成立心理工作室实施“情暖聊职”工程，构建了人人都是思政教师、时时都是育人良机、处处都是育人环境的全场域育人格局。
    </p>
    <div class="img-container">
      <img src="../images/sub12/图片4.png" alt="" />
    </div>
    <p>
      学校马克思主义学院党总支被中共中央授予“全国先进基层党组织”荣誉称号、被教育部立项为“全国党建工作标杆院系”培育建设单位；校团委被团中央授予“全国五四红旗团委”；“英模文化”获评全国职业院校校园文化“一校一品”；学校获批山东省首批红色文化传承示范校，连续12年被评为省级文明单位（校园），连续3年在山东省高职院校办学质量年度考核中位列A类，连续4年在市委绩效考核中荣获一等奖；学生获创新创业大赛奖项51项，其中创新创业国赛银奖、“互联网+”省赛金奖，均实现历史性突破。
    </p>
    <p class="emphsis">教育教学 改革创新</p>
    <p>
      学校贯彻落实现代职业教育体系建设要求，聚焦德技并修，全方位提高人才培养水平。
    </p>
    <p>
      学校着力打造一支信念坚定、对党忠诚，热爱教育事业、热爱学生的“四有”教师队伍。师生多年志愿服务全国“两会”、进博会和博鳌亚洲论坛等国家级大型活动。学校现有全国优秀教师4人、国家级职业教育教师教学创新团队1个；省级优秀教学团队6个、省级黄大年式教学团队3个；山东省教学名师10人，山东省青年技能名师4人，山东省技术技能大师2人；省级职业教育名师工作室4个、省级思政课教学名师工作室1个、省级职业教育“技能大师”工作室2个。
    </p>
    <div class="img-container">
      <img src="../images/sub12/图片5.png" alt="" />
    </div>
    <p>
      对标我省“十强”优势产业集群和建设“六个新聊城”建设要求，对接黄河流域生态保护和高质量发展需求，学校重构专业布局，组建跨学科跨专业的产业学院，构建了智能制造、老年健康服务、网络安全技术、现代农业服务、应急安全和测绘地理信息等专业群，形成了“4+2+N”发展矩阵，为地方经济发展精准培养人才。确立“大平台承载大项目，大项目带动大发展”的建设思路，通过引企入校，杭州喜马拉雅、泰康珞珈、海尔卡奥斯、太平洋光电科技等一批高科技企业入驻平台，产教融合不断深化。
    </p>
    <div class="img-container">
      <img src="../images/sub12/图片6.png" alt="" />
    </div>
    <p>
      学校以全省第四名成绩通过优质校建设验收。连续四年获得全国职业院校技能大赛（高职组）护理赛项一等奖，连续两年承办全国职业院校技能大赛（高职组）信息安全管理与评估赛项并获一等奖。获省级以上各类大赛奖项100余项（包含国赛一等奖8项），获国家级教学成果二等奖2项，获省级教学成果特等奖2项、一等奖5项、二等奖8项。2023年，在省教育厅专业考核中，我校送审专业8个，被评为“A”类或“A+”类4个（健康管理、金融服务与管理、医学检验技术、园林技术），占送审专业的50%。目前，学校拥有国家级骨干专业3个、国家级生产性实训基地1个；省级特色专业7个、省级品牌专业群2个、省级高水平专业群2个；主持国家专业教学资源库1个，主持开发国家专业教学标准1个，主持省级专业教学指导方案2个；建成国家级精品（资源共享）课2门、省级精品课程68门；立项国家职业教育规划教材19部、山东省职业教育规划教材5部。
    </p>
    <p class="emphsis">产教融合 服务发展</p>
    <p>
      学校贯彻中央和省市决策部署，聚焦产业发展，深化产教融合、校企合作，主动服务和融入聊城高质量发展，社会服务能力持续提升。与8个县市区签署战略协议，实现校地资源共建共享、双向赋能。依托山东省新旧动能转换公共实训基地，深化与知名企业合作，“双元”开发了一批国家级、省级规划教材。牵头成立了全国增材制造行业产教融合共同体、全国轴承行业产教融合共同体和全国时空大数据产教融合共同体，立项山东省首批市域产教联合体，牵头成立的聊城第一职教集团被立项为国家首批示范性职教集团。
    </p>
    <div class="img-container">
      <img src="../images/sub12/图片7.png" alt="" />
    </div>
    <p>
      学校建设完成了总投资5.54亿元、建筑面积9万多平方米的聊城市智能（仿真）综合性公共实训基地。基地立足鲁西、服务山东、辐射全国，年服务能力可达3万人次，是聊城市建设职业教育创新发展高地的核心工程，包括增材制造、应急安全、网络安全、文旅、工业互联网、大健康等诸多板块，融社会培训、技能鉴定、技术研发、实习实训、生产经营于一体，即将成为区域产业转型升级的技术高地和面向中小微企业的技术服务中心、企业培训培养紧缺技能人才的实践中心。
    </p>
    <div class="img-container">
      <img src="../images/sub12/图片8.png" alt="" />
    </div>
    <p>
      学校积极探索高职教育国际化路径，不断加大国际交流力度，服务国家“一带一路”倡议，参与国际职业教育标准制定4项，中文+职业技能教材开发4项，累计获得国际技能大赛奖项50余个，具有国际影响力的职业教育标准、职业教育资源被省教育厅列为省级培育项目；荣获“2023职业教育对外交流与合作典型院校”称号，先后与40余个国（境）外高等院校结为友好院校。
    </p>
    <div class="img-container">
      <img src="../images/sub12/图片9.png" alt="" />
    </div>
    <p>
      近年来，学校共承担各类教科研课题1070项，获国家发明专利17项、其他专利230项。学校建有校外实习实训基地300余处，与400余家高端就业单位建立稳定的合作关系。20年来，通过深化产教融合、校企合作，为社会输送了高素质技术技能人才8万多人。
    </p>
    <div class="img-container">
      <img src="../images/sub12/图片10.png" alt="" />
    </div>
    <p>
      新时代，新聊职，新风采。学校将深入贯彻落实习近平新时代中国特色社会主义思想和党的二十大精神，深入学习习近平总书记对职业教育工作作出的重要指示精神，不断推进内涵建设，努力成为大国工匠的摇篮，向着建成国内一流现代化高职院校的目标不断奋进，为建设“六个新聊城”贡献聊职力量。
    </p>
    <div class="img-container">
      <img src="../images/sub12/图片11.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Sub12"
};
</script>

<style scoped lang="less">
.body {
  width: 1440px;
  margin: 160px auto 80px;

  h1 {
    text-align: center;
  }

  .emphsis {
    text-indent: 2em;
    margin: 30px;
    color: #333;
    font-weight: bold;
  }

  p {
    text-indent: 2em;
    margin: 30px;
    color: #333;
    font-size: 18px;
    line-height: 30px;
  }

  .img-container {
    text-align: center;

    img {
      margin: 20px auto;
      width: 1320px;
    }
  }
}
</style>
