<template>
  <div class="body">
    <h1>学院概览</h1>
    <p>
      潍坊职业学院是2001年7月经山东省人民政府批准组建的公办全日制普通高职院校。学院是全国优质专科高等职业院校、中国特色高水平高职学校和专业建设计划建设单位、全国乡村振兴人才培养优质校建设单位、国家级创新创业教育实践基地建设单位、全国1+X证书试点高职院校、职业院校数字校园建设样板校、现代学徒制试点单位。学院入选全国职业院校“教学资源50强”“服务贡献50强”“国际影响力50强”榜单，入选《2022中国职业教育质量年度报告》高职院校服务贡献60所典型院校、学生发展指数100所优秀院校、资源建设60所优势院校、教师发展指数100所优秀院校榜单，入选《2023中国职业教育质量年度报告》50所人才培养卓越高等职业学校、50所服务贡献卓越高等职业学校、50所产教融合卓越高等职业学校三大卓越榜单。连续3年获评山东省高职院校办学质量考核第一名；连续4年获评市直部门（单位）高质量发展综合绩效考核优秀等次。学院党委获评山东省先进基层党组织，获批全国党建工作样板支部1个、山东党建工作样板支部1个。
    </p>
    <p>
      学院坐落在世界风筝都——山东省潍坊市，现有滨海、奎文两个校区，校园总占地2000余亩，建筑面积75万平方米，教学科研仪器设备总值达2.74亿元，图书馆藏书182余万册。学院设有马克思主义学院、农林科技学院、食品药品学院、机电工程学院、汽车工程学院、信息工程学院、化学工程学院、国际商务学院、经营管理学院、文化创意学院等10个全日制二级学院，设有国际教育学院、创业教育学院、继续教育学院、潍坊职业农民学院、潍坊市退役军人职业教育学院等跨专业教学机构，开设“3+2”对口贯通分段培养本科试点专业3个，三年制高职专业45个。现有全日制在校生2万余人。
    </p>
    <p>
      学院现有教职工1100余人，其中高级专业技术职务300余人，博士、硕士800余人。拥有二级教授2人、国家级教学名师6人、教育部新时代职业学校名师名匠1人、泰山学者2人、山东省高等学校教学名师14人、行业职业教育名师12人、山东省教书育人楷模1人、山东省技术技能名师10人，聘任发展顾问、产业教授等270人。拥有国家级教学团队2个、国家级职业教育教师教学创新团队2个、国家级技能大师工作室1个、全国石油和化工教育优秀教学团队3个、山东省技能大师工作室3个、山东省职业教育教学团队16个、山东省高校黄大年式教师团队3个、山东省职业教育名师工作室9个、山东省职业教育技艺技能传承创新平台5个。
    </p>
    <p>
      深化产教融合、学岗融通的“双融”人才培养模式教学改革，先后与潍柴、海尔、歌尔、北汽福田等800余家企业建立了长期稳固的合作关系，校企共建产业学院15个、校企合作办学专业23个。牵头成立全国元宇宙行业产教融合共同体、全国农产品电商职业教育集团、山东省农林职业教育集团、潍坊市机电设备职业教育集团、潍坊市化工职业教育集团、潍坊市现代物流业职业教育集团、潍坊市学前教育产教联盟、潍坊市安全应急管理职业教育联盟7个职业教育集团（联盟），潍坊市化工职业教育集团入选第一批国家示范性职业教育集团（联盟）培育单位名单，潍坊市机电设备职业教育集团被评为山东省骨干职业教育集团。牵头成立山东省1+x证书制度试点联盟，大力推进国家级现代学徒制、山东省混合所有制项目试点，成立全国首批企业合规师培训示范基地（潍坊）全国首个合规师产业学院，校企合作“双元”育人成效显著，获评中国产学研合作创新奖、山东省校企合作一体化办学示范院校、山东省校企合作（产教融合）示范单位。
    </p>
    <p>
      大力推进内涵建设，17个项目通过教育部“高等职业教育创新行动计划”项目认定，包括9个骨干专业、3个生产性实训基地、1个双师型教师培养基地、3个协同创新中心、1个技能大师工作室。学院现有中国特色高水平专业群1个、中央财政支持重点建设专业4个、山东省高职教育高水平专业群5个、山东省品牌专业群2个、山东省高等学校特色专业7个。学院主持、参与8个国家级和省级职业教育专业教学资源库建设，建成国家精品在线开放课程4门，省级精品在线开放课程11门；荣获国家级教学成果奖5项、省级教学成果奖45项。
    </p>
    <p>
      大力开展科技研发与社会服务，全面服务新旧动能转换重大工程和乡村振兴战略。学院建有24个省市级工程技术中心、3个省级技艺技能传承创新平台和20个市级重点实验室。承担国家、省、市级科研课题980余项，主编、参编著作、教材290余本，发表学术论文3000多篇，荣获市级以上科研奖励420余项。组建30余支科技服务团队，近三年开展社会服务130000余人次。成立全国高职院校首家农业经理人学院，潍坊职业农民学院年举办培训班100余期，培训学员1万余人。学院成为乡村振兴战略的人才培训基地和区域技术技能创新服务重要集聚地。
    </p>
    <p>
      深化国际交流与合作，实施“平台孵化、双线并行”工作机制。先后同30多个国家和地区的80余所国（境）外高校或机构开展合作交流，组织300余名教师出国（境）访学研修、参加国际化培训，引进外籍教师50余人，派出近20批次学生赴国（境）外交流实习，培养国际学生2000余人。选派4批农业专家赴刚果（金）承担商务部援非项目，在巴基斯坦、刚果（金）、乌干达等建成海外技术培训中心3个，建设中乌贾思勰学院，承接援乌干达、加纳等国家技术培训项目多项。成立中德潍萨学院，引进德国职业教育的先进理念和教学标准，与澳大利亚、韩国、俄罗斯高校举办中外合作办学项目3项，开展交换生项目和游学活动。开展境外办学，探索“中文+职业技能”国际化发展新模式，政校行企共建老挝职业技术学院和中文工坊。获批教育部“TÜV莱茵数字创新赋能计划”建设单位，立项“未来非洲-中非职业教育合作计划”“汉语桥”线上交流团组、网络中文课堂、中文+职业技能教学资源开发等项目，赋能企业“走出去”，促进中外人文交流和民心相通。
    </p>
    <p>
      深入挖掘“明德尚学 强能善技”校训文化内涵，凝练形成“崇实尚行
      敢于争先”潍职精神。广泛开展学生社团、社会实践、主题教育活动，大力实施大学生人文素养提升“七个一”工程、青年马克思主义者培养工程，弘扬社会主义核心价值观，培养德智体美劳全面发展的技术技能人才。4名学生被评为山东省高校十大优秀学生，学生参加全国职业院校技能大赛获奖100项（其中一等奖36项），参加山东省职业院校技能大赛获奖264项。大力推进大学生创新创业教育，学生参加省级以上创新创业类大赛获奖213项，其中获“互联网+”、挑战杯国赛特等奖1项、金奖3项、银奖8项、铜奖13项。学院立项教育部国家级创新创业教育实践基地建设单位，获评中国创业创新典型示范高校、中国创业创新典型示范基地、全国高职院校创新创业100强、山东省创新创业典型经验高校、山东省创客之家、山东省退役士兵单独招生试点学校、山东省征兵工作先进单位、山东省中华优秀传统文化传承基地。学院构建起全方位、多元化资助体系和就业指导体系，保障学生成长成才，毕业生就业率一直保持在97%以上，倍受用人单位青睐和欢迎。
    </p>
    <p>
      2020年和2023年，职业教育活动周全国启动仪式暨全国职业院校技能大赛（改革试点赛）开幕式分别在学院以及潍坊市成功举办，两任教育部长和省委书记莅临学院和赛场视察工作，并给予充分肯定。
    </p>
    <p>
      学院坚持面向市场、服务发展、促进就业的办学方向，经过60余年的积累与发展，形成了专业建设与区域产业相融合、人才培养过程与生产实践相融合、学院文化与企业文化相融合，学院发展对接区域经济社会发展的“三融合一对接”办学特色，为区域经济社会发展培养了大批高素质技术技能人才。
    </p>
    <p>
      面对新机遇、新任务、新挑战，学院将紧紧围绕“抓党建，促创建”的工作主线，以“双高/职教高地”建设为抓手，聚焦区域高端产业和产业高端，全面推进党建工作、技术技能人才培养高地、技术技能创新服务平台、高水平专业群、高水平双师队伍等重点领域建设，以更加广阔的视野、更加开放的姿态、更加执着的努力，全力打造“引领改革、支撑发展、中国特色、世界水平”的高职院校。
    </p>
  </div>
</template>
<script>
export default {
  name: "Sub7"
};
</script>

<style scoped lang="less">
.body {
  width: 1440px;
  margin: 160px auto 80px;

  h1 {
    text-align: center;
  }

  .bold {
    font-weight: bold;
    font-size: 22px;
    text-indent: 2em;
  }

  p {
    text-indent: 2em;
    margin: 30px;
    color: #333;
    font-size: 18px;
    line-height: 30px;
  }

  .post {
    text-align: center;
    color: #333;
    margin: 0;
    margin-bottom: 80px;
  }

  .flex-2 {
    text-align: center;

    img {
      margin: 20px;
      margin-top: 0;
      width: 640px;
      height: 400px;
    }
    .img1 {
      margin: 40px;
      margin-top: 0;
      width: 820px;
      height: 600px;
    }

    .img2 {
      margin: 40px;
      margin-top: 0;
      width: 420px;
      height: 600px;
    }
  }

  .flex-3 {
    text-align: center;

    img {
      margin: 20px;
      margin-top: 0;
      width: 420px;
      height: 600px;
    }
  }

  .img-container {
    text-align: center;

    img {
      margin: 20px auto;
      width: 1320px;
    }
  }
}
</style>
